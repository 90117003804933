import React from "react";
import ReactGA from "react-ga4";
import Visual from "../Visuals/Visual 12.jpeg"
import { NavLink } from "react-router-dom"
import commonClasses from "../ArticleStyle.css"
import classes from "./knx-member.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Diegem, October 28th 2024</h3>
          <h1>Allorado becomes a KNX member</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="Indumation the award ceremony"/>
            <p className={commonClasses.imageCaption} style={{fontSize: "15px"}}>Allorado CEO Bram Roelandts at the KNX headquarters in Diegem</p>
          </div>

          <p className={commonClasses.contentParagraph}>Allorado is proud to announce that it is now an official member of the KNX Association, joining over 500 global companies shaping the future of smart buildings. As a KNX member, we can develop and certify KNX-compatible products, to even more seamlessly integrate building automation. This partnership paves the way for new products and opportunities in 2025.</p>
          <p className={commonClasses.contentParagraph}>Stay tuned as we launch our KNX-certified products and showcase the power of KNX technology in advancing smart building systems!</p>
          
          <div className={commonClasses.callToAction}>
            <h3>More about our affinity with KNX?</h3>
            <div className={commonClasses.orderRow}>
              <NavLink className={commonClasses.orderButton} to={"/story"} onClick={() => ReactGA.event({ category: "Button", action: "News item to story" })}>Check out our story</NavLink>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>

        <div className={commonClasses.footnote}>Logo © {new Date().getFullYear()} KNX Association</div>
      </div>
    </>
  );
}

export default News;
